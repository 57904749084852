/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import * as finmath from '@mod-system/js/util/finmath';
import * as whintegration from '@mod-system/js/wh/integration';
import * as forms from '@mod-publisher/js/forms';
import * as merge from 'dompack/extra/merge';
import { Webshop } from '@mod-webshop/api/frontend';

import { __applyMethodStyling } from './checkout';

export default class ConfirmQuoteWidget extends forms.RPCFormBase {
  constructor(webshop: Webshop, node, options = {}) {
    super(node);
    this.webshop = webshop;

    for (const node of dompack.qSA(this.node, `.webshop-checkout__paymentmethods .wh-form__fieldline`)) {
      const radio = node.querySelector("input[type=radio]");
      if (!radio)
        continue;

      radio.addEventListener("change", () => this._onPaymentMethodChange());
      __applyMethodStyling(webshop, node, 'paymentmethod');
    }

    this._updateCosts();
  }

  _onPaymentMethodChange() {
    this._updateCosts();
  }

  _updateCosts() {
    let totalcosts = whintegration.config.obj["webshop:quoteinfo"].prepaymenttotal;
    const selectedmethod = this.node.querySelector(`input[name="paymentmethod.paymentmethod"]:checked`);
    if (selectedmethod && selectedmethod.dataset.webshopMethodCost)
      totalcosts = finmath.add(totalcosts, selectedmethod.dataset.webshopMethodCost);

    merge.run(document, { "webshop": { "ordertotal": this.webshop.formatPrice(totalcosts) } });

  }
}

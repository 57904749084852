import * as dompack from "@mod-system/js/dompack";
import ProductListDynPriceHandler from "./pricecalculation";
import { CategoryFilter, CategoryOptionFilter } from "./types";
import { debugFlags } from "@webhare/env";

/* automatically initialized for all nodes with .webshop-productlist-dynprice */
export class FilterListener {
  private readonly pricehandler: ProductListDynPriceHandler;
  private scheduledcheck: NodeJS.Timeout | null = null;
  private lastfiltersetting: string | null = null;
  private readonly hashvar: string;

  constructor(pricehandler: ProductListDynPriceHandler, hashvar: string) {
    if (debugFlags["webshop-filters"])
      console.log(`[webshop-filters] Initializing for pricehandler`, pricehandler);

    this.pricehandler = pricehandler;
    this.hashvar = hashvar;

    addEventListener("change", evt => this.onPossibleFilterChange(evt), true);
    addEventListener("input", evt => this.onPossibleFilterChange(evt), true);

    if (this.hashvar)
      this.applyFromHash();

    this.checkFilters();
  }

  private applyFromHash() {
    const cursetting = new URLSearchParams(location.hash.substring(1)).get(this.hashvar);
    if (!cursetting)
      return;

    for (const toactivate of cursetting.split(".")) {
      const [tofilter, ...vals] = toactivate.split("-");
      const match = dompack.qS<HTMLInputElement>(`[data-webshop-filter^="${tofilter}-"]`);
      console.log(`[webshop-filters] Applying filter ${tofilter} with values ${vals} and activating ${toactivate}`, match);
      if (match) {
        dompack.qSA<HTMLInputElement>(match, `input[data-webshop-filter-value]`).forEach(node => node.checked = vals.includes(node.dataset.webshopFilterValue!.split('-')[0]));
        dompack.qSA<HTMLOptionElement>(match, `option[data-webshop-filter-value]`).forEach(node => node.selected = toactivate.includes(node.dataset.webshopFilterValue!.split('-')[0]));
      }
    }

  }

  private onPossibleFilterChange(evt: Event) {
    if ((evt.target as HTMLElement)?.closest('[data-webshop-filter]'))
      this.scheduleFilterCheck();
  }

  private scheduleFilterCheck() {
    if (this.scheduledcheck)
      return;

    const lock = dompack.flagUIBusy();
    this.scheduledcheck = setTimeout(() => this.checkFilters().finally(() => {
      lock.release();
      this.scheduledcheck = null;
    }), 0);
  }

  private getCurrentFilters(): CategoryFilter {
    const optionfilters: CategoryOptionFilter[] = [];
    const filterparts: string[] = [];

    for (const filter of dompack.qSA('[data-webshop-filter]')) { //check all option sets
      const [filterid, optionlists_str] = filter.dataset.webshopFilter!.split('-');
      const filtersettingids = [];
      const optionlists = optionlists_str ? optionlists_str.split("-").map(str => parseInt(str)) : [];
      const optionvalues = new Set<number>();

      void (filterid); //not used yet? as filtervalue is sufficiently unique

      const selected = dompack.qSA(filter, "[data-webshop-filter-value]:checked");
      if (!selected.length) //TODO can we get eslint to reject if we forget .length here? didn't manage to enable https://typescript-eslint.io/rules/no-unnecessary-condition/
        continue; //ignore this filter

      for (const input of selected) { //and process the individual options
        const [filtervalue, ...optionids] = input.dataset.webshopFilterValue!.split('-');
        if (filtervalue)
          filtersettingids.push(filtervalue);
        for (const opt of optionids)
          optionvalues.add(parseInt(opt));
      }

      optionfilters.push({ optionlists, optionvalues: Array.from(optionvalues) });
      filterparts.push(`${filterid}-${Array.from(filtersettingids).sort().join("-")}`);
    }


    //Find products implementing this optionvalue
    return { filtersetting: filterparts.join('.'), optionfilters };
  }

  /** Check if we need to reapply filters */
  async checkFilters() {
    const filters = this.getCurrentFilters();
    const isfirstcheck = this.lastfiltersetting === null;

    if (!isfirstcheck && this.lastfiltersetting === filters?.filtersetting)
      return; //no changes

    //Update the hash
    this.lastfiltersetting = filters.filtersetting;
    if (!isfirstcheck && this.hashvar) {
      const hashparams = new URLSearchParams(location.hash.substring(1));
      if (!this.lastfiltersetting)
        hashparams.delete(this.hashvar);
      else
        hashparams.set(this.hashvar, this.lastfiltersetting);

      location.replace("#" + hashparams.toString());
    }

    //Update the products (unless this is the first render and nothing is filtered - just leave everything alone then)
    if (!isfirstcheck || filters.filtersetting) {
      if (debugFlags["webshop-filters"])
        console.log(`[webshop-filters] Updating pricehandler with filter`, filters);

      this.pricehandler.setProductFilter(filters);
    }
  }
}

import '@mod-webshop/css/defaultshop.scss';

import { isDomReady, onDomReady, register, stop } from "@webhare/dompack";

import type { WebshopOptions } from "@mod-webshop/js/shopservice";
import Webshop from '@mod-webshop/js/shopservice';
import { registerHandler } from "@mod-publisher/js/forms";
import ListCartWidget from '@mod-webshop/js/widgets/listcart';
import CheckoutWidget from '@mod-webshop/js/widgets/checkout';
import ConfirmQuoteWidget from '@mod-webshop/js/widgets/confirmquote';
import { _updateAddedProductPrice } from '@mod-webshop/js/widgets/addedcartproduct';
import SearchWidget from '@mod-webshop/js/widgets/search';
import * as favlist from "@mod-webshop/js/shopservice/favlist";
import { debugFlags } from "@webhare/env";
import { frontendConfig } from "@webhare/frontend";

export { Webshop };


// For now we assume a page has only one shop.
let webshop: Webshop | undefined;

/** Initialize the webshop */
export async function setupWebshop(options: Partial<WebshopOptions> = {}): Promise<Webshop> {
  if (!isDomReady())
    await new Promise<void>(resolve => onDomReady(() => resolve()));
  if (webshop)
    throw new Error("Duplicate webshop initialization");

  const newwebshop = new Webshop(options);
  webshop = newwebshop;
  register('webshop-cart', node => new ListCartWidget(newwebshop, node, options));
  register('.webshop-search', node => new SearchWidget(newwebshop, node));
  register('.webshop-retrypaymentlink', node => node.addEventListener("click", evt => {
    stop(evt);
    newwebshop.retryPayment(node.dataset.retrydata);
  }));
  register<HTMLInputElement>("input.webshop-product__favorite", node => favlist.setupFavorite(webshop!, node));
  register('.webshop-addedcartproduct', node => _updateAddedProductPrice(webshop, node));
  ///@ts-ignore requires WH update and then we can probably also remove :HTMLFormElement x4
  registerHandler('webshop:checkout', (node: HTMLFormElement) => new CheckoutWidget(newwebshop, node, options));
  registerHandler('webshop:confirmquote', (node: HTMLFormElement) => new ConfirmQuoteWidget(newwebshop, node, options));

  return webshop;
}

export function getWebshop(): Webshop {
  if (!webshop)
    throw new Error("No shop setup for this page" + (isDomReady() ? "" : " (but domready hasn't fired yet!"));
  return webshop;
}

/// Frontend debug support
if (debugFlags.sho && frontendConfig.obj.webshoppagedata)
  console.log("[sho] pagedata", frontendConfig.obj.webshoppagedata);

const webshopconfig = frontendConfig.webshop as { cached?: string } | undefined;
if (webshopconfig?.cached)
  console.log(`Cached page - generated at ${webshopconfig?.cached} (${Math.floor((Date.now() - Date.parse(webshopconfig?.cached)) / 1000)} seconds ago)`);

window.getWebshop = getWebshop;

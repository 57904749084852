import * as dompack from 'dompack';
import AutoSuggest from 'dompack/components/autosuggest';
import { Webshop } from '@mod-webshop/api/frontend';

export default class SearchWidget {
  node;
  webshop;
  _autosuggester;

  constructor(webshop: Webshop, node: HTMLElement) {
    this.node = node;
    this.webshop = webshop;

    const searchfield = dompack.qS<HTMLInputElement>(node, "input.webshop-search__searchfield");
    if (searchfield) {
      searchfield.autocomplete = "off";
      this._autosuggester = new AutoSuggest(searchfield, this, { minlength: 1 });
      searchfield.addEventListener("dompack:autosuggest-selected", () => this._onSuggestSelected());
    }
  }

  _onSuggestSelected() {
    window.setTimeout(() => this.node.closest<HTMLFormElement>('form')!.submit(), 1);
  }

  async lookup(q: string) {
    const request = await fetch("/.webshop/suggest.shtml?w=" + encodeURIComponent(JSON.stringify(this.webshop._getRPCPointer())) + "&q=" + encodeURIComponent(q));
    const response = await request.json();
    return response.results;
  }
}

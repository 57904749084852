import {Tab, Modal, Tooltip, Popover, Button, Dropdown, Offcanvas, Util} from 'bootstrap/dist/js/bootstrap.js';
import * as popperjs from '@popperjs/core';
import * as dompack from 'dompack';
import Glide from '@glidejs/glide';
import '@glidejs/glide/dist/css/glide.core.min.css';
import '@glidejs/glide/dist/css/glide.theme.min.css';
import 'lazysizes';

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))

var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) 
{
  return new Tooltip(tooltipTriggerEl)
})

// toggle ul in sidemenu
document.querySelectorAll('.has-submenu > .submenu-toggle').forEach(item => {
  item.addEventListener('click', function () 
  {
    this.nextElementSibling.classList.toggle('d-block');
    this.classList.toggle('collapse--open');
    this.parentNode.parentNode.classList.remove('collapse--open');
  });
});

dompack.onDomReady(() =>
{
    // logo slider widget
    var logoslider = document.querySelector('.brand-slider');
    if (typeof (logoslider) != 'undefined' && logoslider !== null)
    {
      new Glide('.brand-slider', 
      {
        type: 'carousel',
        perView: 5,
        gap: 20,
        autoplay: 3000,
        breakpoints: 
        {
          1400: {
            perView: 5
          },
          992: {
            perView: 4
          },
          768: {
            perView: 3
          },
          480: {
            perView: 2
          }
        }
      }).mount()
    }

    // pillen widget (innerlinks)
    var innerlinks = document.querySelector('.widget-innerlinks');
    if (typeof (innerlinks) != 'undefined' && innerlinks !== null)
    {

      if (document.querySelectorAll('.widget-innerlinks .glide__slide').length <= 5)
      {
          if(!window.matchMedia("(max-width:992px)").matches) 
          {
            innerlinks.classList.add('noglide');

          } else {

            new Glide('.widget-innerlinks', 
            {
              type: 'carousel',
              perView: 5,
              gap: 20,
              breakpoints: 
              {
                1400: {
                  perView: 5
                },
                992: {
                  perView: 4
                },
                768: {
                  perView: 3
                },
                480: {
                  perView: 1.5
                }
              }
            }).mount()

          }
      
      } else {

        new Glide('.widget-innerlinks', 
        {
          type: 'carousel',
          perView: 5,
          gap: 20,
          breakpoints: 
          {
            1400: {
              perView: 5
            },
            992: {
              perView: 4
            },
            768: {
              perView: 3
            },
            480: {
              perView: 1.5
            }
          }
        }).mount()
      }
    }

    // product slider widget
    var productslider = document.querySelector('.products-slider');
    if (typeof (productslider) != 'undefined' && productslider !== null)
    {
      new Glide('.products-slider', 
      {
        type: 'carousel',
        perView: 3,
        gap: 20,
        autoplay: 3000,
        breakpoints: 
        {
          1400: {
            perView: 3
          },
          992: {
            perView: 3
          },
          768: {
            perView: 2
          },
          480: {
            perView: 2
          }
        }
      }).mount()
    }

    uspCycle();

    setInterval(function()
    {
       uspCycle(); 
    }, 8000);

});

function uspCycle()
{
    var elements = document.querySelectorAll('.usp_item')
    
    if(!elements)
        return;

    if(!window.matchMedia("(max-width:767px)").matches)
        return;

    for (let i = 0; i < elements.length; i++) 
    {
      setTimeout(function() 
      {
        elements[i].style.opacity = 1;
      }, 2000 * i);
      if (i < (elements.length)) 
      {
        setTimeout(function() 
        {
          elements[i].style.opacity = 0;
        }, 2000 * (i + 1));
      }
    }
}


dompack.onDomReady(() =>
{
    const lazyCarousels = document.querySelectorAll(".carousel.lazy");

    lazyCarousels.forEach(function(carousel) {
        carousel.addEventListener("slide.bs.carousel", function(event) {
            const lazyImage = event.relatedTarget.querySelector("img[data-src]");
            if (lazyImage) {
                lazyImage.src = lazyImage.dataset.src;
                lazyImage.removeAttribute("data-src");
            }
        });
    });
});


/* eslint-disable */
/// @ts-nocheck -- Bulk rename to enable TypeScript validation

import * as dompack from 'dompack';
import * as dialogapi from 'dompack/api/dialog';
import * as whintegration from '@mod-system/js/wh/integration';
import * as errorreporting from '@mod-system/js/wh/errorreporting';
///@ts-ignore rpc.json deprecated for TS anyway
import * as webshoprpc from '../internal/api.rpc.json';
import { getDefaultAuth } from '@mod-wrd/js/auth';
import Product from './product';
import ProductListDynPriceHandler from "../internal/pricecalculation";
import ProductOrderingControl from "../internal/sorting";
import * as finmath from '@mod-system/js/util/finmath';
import * as discounts from '../support/discounts';
import * as webshopmath from '../internal/webshopmath';
import { getTid } from "@mod-tollium/js/gettid";
import { FilterListener } from "../internal/categoryfilters";
import { getSession, getLocal, setLocal } from "@webhare/dompack";
import { WebShopNumDecimals } from '../internal/types';
import { ProductTypeConstructor } from "./product";
import { Money, MoneyFormatOptions } from '../internal/temp_money';
import { ProductEnabledOptionsResult } from '@mod-webshop/api/frontend/types';
import { getCheckoutWidget } from '../widgets/checkout';

export type UpdatedPricesEvent = CustomEvent<{
  webshop: WebShop;
  currentprice: string | "onrequest";
}>;

declare global {
  interface GlobalEventHandlersEventMap {
    "webshop:updatedprices": UpdatedPricesEvent;
  }
}


function compareProductRows(a, b) {
  // Compare product id
  if (a.product != b.product)
    return false;

  // Compare options (by optionid)
  if (a.options.map(opt => opt.optionid).sort().join("|") != b.options.map(opt => opt.optionid).sort().join("|"))
    return false;

  if (!a.configuration && !b.configuration)
    return true;

  return false;
}

function getSortedIntSet(arr: number[]): number[] {
  return [...new Set(arr)].sort((a, b) => a - b);
}

export interface WebshopOptions {
  onNewCartDom(dom: HTMLElement): void;
  onReady: any;
  catalog: any;
  decimals(costtable: any, total: any, decimals: any): unknown;
  catalogroot: string;
  productpagetypes: Array<{
    name: string;
    handler: ProductTypeConstructor;
  }>;
  priceFormat: MoneyFormatOptions;
  debug: boolean;
  listprices: boolean;
}

interface WebshopConfig {
  catalogroot: string;
}

export class WebShop {
  autocategoryfilter: FilterListener;
  options: WebshopOptions;
  _currentchangingfields: never[];
  storageprefix: string;
  debug: boolean | undefined;
  _dialogopen: any;
  _calculatedcartvalid: any;
  _calculatedcart: { numarticles: any; preshippingtotal: any; products: Array<{ amount: any; baseprice: any; priceonrequest: any; discount: string; prediscountlinetotal: string; title: any; link: string; thumbnail: any; options: any; extralines: any; brand: any; sku: any; categorypath: any; product: any; discounts: never[] }>; orderdiscounts: Array<{ linetotal: string; title: any; active: any; candelete: boolean; type: any; couponcode: any } | { active: boolean; candelete: boolean; type: string; linetotal: string; title: string }>; productdiscounts: any[]; discounttotal: number; customlines: any; priceonrequest: any };
  _cart: any;
  _describedcart: any;
  _checkoutinfo: any;

  constructor(options: Partial<WebshopOptions>) {
    this._currentchangingfields = [];

    const webshopconfig = ((whintegration.config || {}).webshop || {}) as Partial<WebshopConfig>;
    //ADDME load decimals, separator from webshop settings
    this.options = {
      debug: false,
      priceFormat: {
        decimalSeparator: ',',
        thousandsSeparator: '.',
        minDecimals: 2,
        ...options?.priceFormat
      },
      catalogroot: webshopconfig?.catalogroot ?? "",
      productpagetypes: [],
      listprices: true, //disabling calculation of listprices and discounts cleans up logging due by reducing calculations
      onReady: null,
      ...options
    };
    if (!this.options.catalogroot)
      throw new Error("Webshop not properly configured");

    this.storageprefix = `wh-webshop:${this.options.catalogroot}:`;
    this.debug = this.options.debug || dompack.debugflags.sho;
    this.detectReferrer();

    // Load cart before initializing elements (they might need a valid cart)
    try {
      this._loadCart();
    } catch (e) {
      this.crashWebshop(e, 'Unable to load the cart');
    }

    dompack.onDomReady(() => this._initElements());

    if (whintegration.config.obj["webshop:instructions"])
      whintegration.config.obj["webshop:instructions"].forEach(instr => this._executeInstruction(instr));

    if (this.options.onReady)
      this.options.onReady(this);
  }

  detectReferrer() {
    if (document.referrer && !document.referrer.startsWith(whintegration.config.siteroot)) {
      //TODO configurable how far back we accept referrers? currently 14 days..
      const referrerinfo = getLocal("webshop.referrerinfo");
      if (!referrerinfo || referrerinfo.when < Date.now() - 14 * (24 * 60 * 60 * 1000))
        setLocal("webshop.referrerinfo", { when: Date.now(), referrer: document.referrer });
    }
  }

  //returns "/shop"
  getCatalogPathname() {
    return '/' + this.options.catalogroot.split('/').slice(3).join('/');
  }

  _executeInstruction(instr) {
    if (instr.instr == "emptycart") //after payment completes or goes into pending
    {
      this.emptyCart();
      return;
    }
    console.error("Unrecognized instruction", instr);
  }

  ///Report various updates/errors through a dialog - TODO Remove, dialogs not our decision
  async reportStatus(message, options?: dialogapi.DialogOptions) {
    if (this._dialogopen) //prevent races triggering multiple dialogs if our user is very fast to click
      return '';

    this._dialogopen = true;
    await dialogapi.runMessageBox(message, [{ title: "OK" }], {
      allowcancel: true,
      ...options
    });
    this._dialogopen = false;
  }

  ///Clear the state for the webshop completely (carts etc). mostly a testframework thing
  resetState() {
    setLocal(this.storageprefix + 'cart', null);
    setLocal(this.storageprefix + 'checkout', null);
  }

  _getProductLink(row) {
    //these links are JS-only and not SEO relevant, so just throw some ids on the URL. The server will rewrite it to a nice url
    let url = `${this.options.catalogroot}gotoproduct?id=${row.product}&options=${encodeURIComponent(JSON.stringify(row.options.map(row => row.optionid)))}`;
    if (row.configuration)
      url += `&config=${encodeURIComponent(JSON.stringify(row.configuration))}`;
    return url;
  }

  /** Get current cart contents */
  getCart() {
    if (!this._calculatedcartvalid) {
      this._calculatedcart = this._calculateCart();
      this._calculatedcartvalid = true;
    }
    return this._calculatedcart;
  }

  _calculateCart() {
    const products = [];
    const deletabletypes = ['singleusecode', 'multiusecode'];

    // calculate the product/order discounts
    let discounttotal = 0;
    for (const product of this._cart.products) {
      const productrec =
      {
        amount: product.amount,
        baseprice: product.baseprice,
        priceonrequest: product.priceonrequest || false,
        discount: finmath.subtract(product.baseprice, product.itemprice),
        prediscountlinetotal: finmath.multiply(product.quantityprice, product.amount),
        title: product.title,
        link: this._getProductLink(product),
        thumbnail: product.thumbnail,
        options: product.options.filter(opt => !opt.hiddendefault).map(opt => ({ label: opt.label, selected: opt.selected })),
        extralines: product.extralines || [],
        brand: product.brand,
        sku: product.sku,
        categorypath: product.categorypath,
        product: product.product,
        discounts: []
      };

      for (const discount of product.appliedproductdiscounts) {
        const discountrec = {
          product,
          discount,
          linetotal: finmath.subtract(0, discount.value),
          active: discount.active,
          candelete: deletabletypes.includes(discount.type),
          type: discount.type,
          couponcode: discount.code
        };
        productrec.discounts.push(discountrec);
        if (discount.active)
          discounttotal = finmath.add(discounttotal, discount.value);
      }

      products.push(productrec);
    }

    /// Gather product discounts
    const productdiscounts = [];
    products.forEach(row => productdiscounts.push(...row.discounts));

    const orderdiscounts = [];
    let totalsofar = this._cart.products.reduce((acc, row) => finmath.add(acc, finmath.multiply(row.itemprice, row.amount)), '0');
    for (const discount of this._cart.appliedorderdiscounts) {
      if (discount.active) {
        discounttotal = finmath.add(discounttotal, discount.value);
        totalsofar = finmath.subtract(totalsofar, discount.value);
      }

      const orderdiscountrec =
      {
        linetotal: finmath.subtract(0, discount.value),
        title: discount.wrd_title,
        active: discount.active,
        candelete: deletabletypes.includes(discount.type),
        type: discount.type,
        couponcode: discount.code
      };

      if (discount.type === "singleusecode" || discount.type === "multiusecode") //for feedback, add the code to the title
      {
        if (orderdiscountrec.title)
          orderdiscountrec.title = `${orderdiscountrec.title} (${discount.code})`;
        else
          orderdiscountrec.title = getTid("webshop:frontend.cart.discountcode", discount.code);
      }

      orderdiscounts.push(orderdiscountrec);
    }

    /// Add loyaltypoints discount
    if (this._cart.useloyaltypoints && this._cart.cache.loyalty) {
      const spend = this._cart.useloyaltypoints;
      const linetotal = finmath.multiply(-spend, this._cart.cache.loyalty.value);

      orderdiscounts.push({
        active: true,
        candelete: true,
        type: 'loyaltypoints',
        linetotal: linetotal,
        title: spend > 1 ? getTid("webshop:frontend.cart.loyaltypoints", spend)
          : getTid("webshop:frontend.cart.loyaltypoint1", spend)
      });
      totalsofar = finmath.add(totalsofar, linetotal);
    }

    totalsofar = this.roundMoney(totalsofar, "half-toward-infinity");

    const customlines = this._cart.customlines || [];
    customlines.forEach(line => totalsofar = finmath.add(totalsofar, line.linetotal));

    const preshippingtotal = totalsofar;

    return {
      numarticles: this._cart.products.reduce((acc, row) => acc + row.amount, 0),
      preshippingtotal,
      products,
      orderdiscounts,
      productdiscounts,
      discounttotal,
      customlines,
      priceonrequest: this._cart.products.some(row => row.priceonrequest)
    };
  }

  roundMoney(value, mode) {
    return webshopmath.roundMoney(value, mode, WebShopNumDecimals);
  }

  ///nicely format a price
  formatPrice(price: string | Money | "onrequest") {
    if (price === "onrequest")
      return getTid("webshop:frontend.cart.priceonrequest");
    return new Money(price).format(this.options.priceFormat);
  }

  //get the catalog root, ending in a slash
  getCatalogRoot() {
    return this.options.catalogroot;
  }

  //go to the checkout page
  gotoCheckout() {
    location.href = this.options.catalogroot + 'webshop/checkout';
  }

  //go to the request quote page
  gotoRequestQuote() {
    location.href = this.options.catalogroot + 'webshop/checkout/requestquote';
  }

  ///the webshop is confused, report any error and reset our cart
  async crashWebshop(errorobj, msg) {
    const savecart = this._cart;
    const timeout = dompack.createDeferred();
    dialogapi.runMessageBox("An unexpected error has occurred. Our apologies for the inconvenience.", [{ title: "OK" }]).then(timeout.resolve);

    if (!errorobj)
      errorobj = new Error(msg);
    console.error("Cart describe failed, will need to reset it", errorobj, savecart);
    errorreporting.reportException(errorobj, { crashdata: { msg: msg, cart: savecart } });

    if (!dompack.debugflags.sho && whintegration.config.islive) {
      window.setTimeout(timeout.resolve, 20 * 1000); //we'll give up on reporting an exception after 20 seconds
      await timeout.promise; //wait for the error upload to complete or timeout
      this.resetState();
      location.reload();
    }
  }

  isCartEmpty() {
    return this._cart.products.length == 0;
  }

  ///empty the cart and order (but not 'recently seen' etc)
  emptyCart() {
    this._clearCart();
    this._cartUpdated({ productschange: true });
  }

  ///get the RPC pointer for this webshop
  _getRPCPointer() {
    return { path: location.pathname, catalog: this.options.catalog };
  }

  /** Given a cost table, find the lowest applicable cost
    @param costtable
    @cell costtable.fromtotal
    @cell costtable.cost
    @param total
    @return The lowest cost from the costtable entries with fromtotal <= total
  */
  _getCostFromTable(costtable, total) {
    return webshopmath.getCostFromTable(costtable, total, this.options.decimals);
  }

  //initialize wellknown elements
  _initElements() {
    this._refreshCartFields();
    dompack.register('webshop-product', node => {
      new Product(this, node);
    });

    dompack.register(".webshop-products--ordering", node => new ProductOrderingControl(node));
    dompack.register('.webshop-productlist-dynprice', node => {
      const pricehandler = new ProductListDynPriceHandler(this, node);
      //By default a global handler is installed that links the first `.webshop-productlist-dynprice` handlers to these filters
      if (!this.autocategoryfilter)
        this.autocategoryfilter = new FilterListener(pricehandler, 'cf');
    });
  }

  _clearCart() {
    this._cart = {
      products: [],
      appliedorderdiscounts: [],
      couponcodes: [],
      authsession: '',
      checkoutform: {},
      codediscounts: [],
      cache: {}
    };
  }

  resetCheckoutForm() //debugging option, triggers a reload!
  {
    if (!this._cart)
      this._clearCart();
    this._cart.checkoutform = {};
    this._cartUpdated();
    location.reload();
  }

  _loadCart() {
    this._clearCart();

    // orderdiscounts is calculated from the order/code discounts

    const loadcart = getLocal(this.storageprefix + 'cart');
    if (loadcart) {
      if (loadcart.products)
        this._cart.products = loadcart.products.filter(row => row.amount > 0 && finmath.isValidPrice(row.quantityprice));

      const loadcheckout = getLocal(this.storageprefix + 'checkout') ?? getSession(this.storageprefix + 'checkout'); //pre 5.3 webshops hold checkout data in session storage, so check that as a fallback until ~ Aug 2024?
      if (loadcheckout) {
        if (loadcheckout.cache)
          this._cart.cache = loadcheckout.cache;

        if (loadcheckout.couponcodes)
          this._cart.couponcodes = loadcheckout.couponcodes;

        if (loadcheckout.codediscounts)
          this._cart.codediscounts = loadcheckout.codediscounts;

        if (loadcheckout.useloyaltypoints)
          this._cart.useloyaltypoints = loadcheckout.useloyaltypoints;

        const issameuser = loadcheckout.authsession === getDefaultAuth().getCurrentSessionId();
        if (loadcheckout.checkoutform) {
          this._cart.checkoutform = {};
          for (const keyname of Object.keys(loadcheckout.checkoutform)) {
            if (!issameuser && !keyname.match(/^(paymentmethod|shippingmethod)\./))
              continue;

            this._cart.checkoutform[keyname] = loadcheckout.checkoutform[keyname];
          }
        }
      }
    }

    this._calculatePrices();

    if (this.debug)
      console.log("[sho] webshop restored cart", this._cart);

    return this._cart;
  }

  _updateCheckoutForm(val) {
    this._cart.authsession = getDefaultAuth().getCurrentSessionId();
    this._cart.checkoutform = val;
    this._cartUpdated({ cartupdateevent: false });
  }

  _processDescribedCart(proposedcart, describedcart, { productschange = false } = {}) {
    const returnissues = [];

    this._describedcart = describedcart;
    this._cart.products = proposedcart.products;

    describedcart.issues.forEach(issue => {
      if (issue.type == 'invalidproduct') {
        const rowidx = this._cart.products.findIndex(row => row.lineuid === issue.lineuid);
        returnissues.push({ ...issue, row: this._cart.products[rowidx] });
        if (rowidx !== -1)
          this._cart.products.splice(rowidx, 1);
      }
      if (issue.type === 'notenoughstock') {
        const rowidx = this._cart.products.findIndex(row => row.lineuid === issue.lineuid);
        if (rowidx !== -1) {
          returnissues.push({ ...issue, row: this._cart.products[rowidx] });
          if (issue.allowed !== 0)
            this._cart.products[rowidx].amount = issue.allowed;
          else
            this._cart.products.splice(rowidx, 1);
        }
      }
    });

    this._cart.products.forEach(row => {
      const prod = this._describedcart.products.find(e => e.lineuid === row.lineuid) ||
      {
        extralines: [],
        title: row.title,
        discounts: []
      };
      Object.assign(row, prod);
    });

    //Reflect described cart into our real cart
    this._cart.couponcodes = describedcart.nonproducts.filter(row => ['singleusecode', 'multiusecode', 'giftcard'].includes(row.type)).map(row => row.code);
    //Get unique codes
    this._cart.couponcodes = this._cart.couponcodes.filter((code, idx) => this._cart.couponcodes.indexOf(code) == idx);
    this._cart.codediscounts = describedcart.codediscounts;
    this._cart.giftcards = describedcart.giftcards;
    this._cart.cache.loyalty = describedcart.loyalty;
    this._cart.useloyaltypoints = describedcart.loyalty ? describedcart.loyalty.spend : 0;
    this._cart.customlines = describedcart.nonproducts.filter(row => row.type == 'custom');

    this._cartUpdated({ productschange });
    return { issues: returnissues };
  }

  async _describeCart(proposedcart, options) {
    options = { foreground: true, productschange: false, ...options };
    let lock;
    if (options.foreground)
      lock = dompack.flagUIBusy();

    try {
      const describedcart = await webshoprpc.describeCart(this._getRPCPointer(), proposedcart);
      if (this.debug)
        console.log("[sho] Received cart description", describedcart);

      return this._processDescribedCart(proposedcart, describedcart, { productschange: options.productschange });
    } finally {
      if (lock)
        lock.release();
    }
  }

  _setCheckoutInfo(checkoutinfo) {
    this._checkoutinfo = checkoutinfo;
    this._cartUpdated();
  }

  _calculatePrices() {
    // Calculate the quantity prices for all products
    for (const product of this._cart.products) {
      product.quantityprice = discounts.applyProductQuantityDiscounts({
        product: product.product,
        amount: product.amount,
        price: product.baseprice,
        discounts: product.discounts
      });
    }

    let producttotal = 0;
    for (const product of this._cart.products)
      producttotal = finmath.add(producttotal, finmath.multiply(product.quantityprice, product.amount));

    const cartproductids = this._getCartProductIds();
    const codediscounts = this._cart.codediscounts || [];
    let ordertotal = 0;
    for (const product of this._cart.products) {
      const productres = discounts.applyProductPromotionDiscounts({
        product: product.product,
        amount: product.amount,
        price: product.quantityprice,
        discounts: product.discounts.concat(codediscounts),
        cartproductids,
        producttotal,
        couponcodes: this._cart.couponcodes
      });

      product.itemprice = productres.promotionprice;
      product.appliedproductdiscounts = productres.promotions;
      ordertotal = finmath.add(ordertotal, finmath.multiply(product.itemprice, product.amount));
    }

    // gather all discounts (codes and per-product), filter out duplicates based on .wrd_id
    let alldiscounts = this._cart.products.reduce((a, v) => a.concat(v.discounts), codediscounts);
    alldiscounts = alldiscounts.filter((elt, idx, arr) => arr.findIndex(e => e.wrd_id === elt.wrd_id) === idx);

    const orderres = discounts.applyOrderDiscounts({ ordertotal, producttotal, discounts: alldiscounts, cartproductids, couponcodes: this._cart.couponcodes });
    this._cart.appliedorderdiscounts = orderres.promotions;
  }

  _getShippingCost(coststructure, preshippingtotal) {
    let costtable = coststructure.costtable;
    if (!costtable) //cached old session
    {
      console.warn("Using a legacy cost structure!");
      costtable = [{ cost: coststructure.cost }];
    }
    return this._getCostFromTable(costtable, preshippingtotal);
  }

  _getPaymentCost(coststructure, prepaymenttotal) {
    return this._getCostFromTable(coststructure.costtable, prepaymenttotal);
  }

  _cartUpdated(options = {}) {
    this._calculatedcartvalid = false;
    this._calculatePrices();
    this._refreshCartFields();

    options = { cartupdateevent: true, productschange: false, preshippingchange: false, ...options };

    //store cart. products go to longterm storage, other data should be killed after browser restart
    setLocal(this.storageprefix + 'cart', { products: this._cart.products });
    setLocal(this.storageprefix + 'checkout', { ...this._cart, products: undefined });

    //and update baskets etc
    if (options.cartupdateevent) {
      const detail = {
        webshop: this,
        productschange: options.productschange || options.preshippingchange,
        preshippingchange: options.preshippingchange
      };
      dompack.dispatchCustomEvent(window, "webshop:cartupdated", { bubbles: true, cancelable: false, detail: detail });
    }
  }

  _refreshCartFields() {
    //update merges
    // let cart = this.getCart();
    // merge.run(document.body, { "webshop": { "ordertotal":       this.formatPrice(cart.totalprice)
    //                                                  }
    //                                     });
  }

  _findCartRow(productrow) {
    return this._cart.products.findIndex(row => compareProductRows(productrow, row));
  }

  async _addToCart(productrow, options = {}) {
    if (!(productrow.amount > 0) || (!productrow.priceonrequest && !finmath.isValidPrice(productrow.baseprice)) || !productrow.options || !productrow.title || !("categorypath" in productrow)) {
      console.log({ productrow });
      throw new Error("Invalid productrow");
    }

    let lineuid, orgamount, newamount;
    const proposedcart = { ...this._cart, products: this._cart.products.slice() };

    const rowidx = this._findCartRow(productrow);
    let updatedrow;
    if (rowidx == -1) {
      const existingids = new Set(proposedcart.products.map(p => p.lineuid));
      const baseid = Number(Date.now());
      let cntr = 1;
      while (lineuid = `${baseid}_${cntr}`, existingids.has(lineuid))
        ++cntr;

      orgamount = 0;
      newamount = productrow.amount;

      updatedrow = {
        product: productrow.product,
        options: productrow.options,
        quantityprice: null,
        itemprice: null,
        configuration: productrow.configuration || null,
        lineuid
      };
      proposedcart.products.push(updatedrow);
    } else {
      updatedrow = proposedcart.products[rowidx];
      orgamount = updatedrow.amount;
      newamount = orgamount + productrow.amount;
      lineuid = updatedrow.lineuid;
    }

    updatedrow.baseprice = productrow.baseprice;
    updatedrow.amount = newamount;
    updatedrow.title = productrow.title;
    updatedrow.thumbnail = productrow.thumbnail;
    updatedrow.discounts = productrow.discounts;
    updatedrow.brand = productrow.brand;
    updatedrow.sku = productrow.sku;
    updatedrow.categorypath = productrow.categorypath;
    updatedrow.options = productrow.options; //clone any updated texts
    if (productrow.priceonrequest)
      updatedrow.priceonrequest = true; //only set, never cleared (TODO or should 'on request' rows be unqiue? eg match only other on-request rows?)

    let addedamount = productrow.amount;
    const describeresult = await this._describeCart(proposedcart, { productschange: true });
    for (const issue of describeresult.issues) {
      if (issue.lineuid === lineuid) {
        if (issue.type === "notenoughstock") {
          // Current product has stock problems, calculate how many items were actually added from the new cart row.
          const postrowidx = this._findCartRow(productrow);
          newamount = postrowidx === -1 ? 0 : this._cart.products[0].amount;

          addedamount = Math.max(0, newamount - orgamount);
        } else {
          console.error(issue);
          await this.reportStatus("An unexpected error occurred adding the product");
          throw new Error("An unexpected error occurred adding the product");
        }
      }
    }

    return { lineuid, addedamount, totalamount: newamount };
  }

  /** Returns the unique ids of the products in the cart
  */
  _getCartProductIds() {
    return getSortedIntSet(this._cart.products.map(e => e.product));
  }

  _getCartItems() {
    return this._cart.products.map(e => ({ product: e.product, options: e.options, amount: e.amount, configuration: e.configuration }));
  }

  /** Returns the content of 'webshop-extradata'
      @return Configuration/page data
  */
  _getExtraData() {
    const metainfonode = document.getElementById("webshop-extradata");
    if (!metainfonode)
      throw new Error(`Could not find webshop-extradata node`);

    return JSON.parse(metainfonode.textContent);
  }

  getCartProductByLineUid(lineuid) {
    const prod = this._cart.products.find(p => p.lineuid === lineuid);
    if (!prod)
      return null;

    return ({
      product: prod.product,
      options: prod.options,
      configuration: prod.configuration,
      title: prod.title,
      lineuid: lineuid,
      thumbnail: prod.thumbnail,
      itemprice: prod.itemprice
    });
  }

  async retryPayment(retrydata) {
    try {
      const result = await webshoprpc.retryPayment(this._getRPCPointer(), retrydata);
      if (result.type === "ok")
        whintegration.executeSubmitInstruction(result.submitinstruction);
    } catch (e) {
      console.log(e);
    }
  }

  _getIssueText(issue) {
    if (issue.type === "notenoughstock") {
      return getTid("webshop:frontend.checkout.oneormoreproductsnotinstock");
    } else if (issue.type === "invalidproduct") {
      return getTid("webshop:frontend.checkout.oneormoreproductsnotavailableanymore");
    } else if (issue.type == "invalidcouponcode") {
      return getTid("webshop:frontend.checkout.discountcodeisinvalid", issue.couponcode);
    } else if (issue.type == "invalidshippingmethod") {
      return getTid("webshop:frontend.checkout.invalidshippingmethod");
    } else {
      return issue.type;
    }
  }

  async _handleDescribeResult(issues) {
    let focusonclose;

    if (issues.find(_ => _.type == "invalidshippingmethod")) {
      //reset shipping method
      const selected = dompack.qS("input[name='shippingmethod.shippingmethod']:checked");
      if (selected)
        selected.checked = false; // a serverside triggered change does not need to go through event handling

      //if you change the shippingcountry, shippingmethod should be next so don't explicitly popup about that. this issue does not need to be intercepted
      if (this._currentchangingfields.includes("shippingcountry")) {
        //FIXME should this code be in checkout.ts ... ?
        const firstmethod = dompack.qS(`input[name="shippingmethod.shippingmethod"]:not(:disabled)`);
        if (firstmethod)
          dompack.changeValue(firstmethod, 'true');

        issues = issues.filter(_ => _.type != "invalidshippingmethod");
      }
      focusonclose = document.querySelector('[data-wh-form-group-for~="shippingmethod.shippingmethod"]');
    }
    this._currentchangingfields = [];

    if (!issues.length)
      return;

    if (issues.find(_ => _.type == "invalidcouponcode"))
      focusonclose = document.querySelector('.webshop-checkout__addcouponcode');

    if (issues.length > 0) {
      if (this.debug)
        console.log('[sho] issues!', issues);

      if (!dompack.dispatchCustomEvent(document.body, "webshop:checkoutissues", { bubbles: true, cancelable: true, detail: { webshop: this, issues, focusonclose } }))
        return; //issue reporting was cancelled
      if (!issues.length)
        return; //issues were cleared
    }

    const issuetexts = issues.map(issue => this._getIssueText(issue));
    this.reportStatus(issuetexts.join("\n"), { focusonclose });
  }

  getCartItemsForFilter() {
    return this._cart.products.map((e) => ({ product: e.product, options: e.options.map(o => o.optionid), configuration: e.configuration, amount: e.amount }));
  }

  async _getFilterOptionValues(products, options) {
    const lock = dompack.flagUIBusy();
    try {
      // add the cart for stock purposes
      options.cartitems = this._cart.products.map((e) => ({ product: e.product, options: e.options, configuration: e.configuration, amount: e.amount }));
      options.cartitems = this.getCartItemsForFilter();
      return await webshoprpc.selectOptionsByFilter(this._getRPCPointer(), products, options);
    } finally {
      lock.release();
    }
  }

  /** @cell options.productvalues
      @cell options.allowselectionoutofstock
  */
  async _getProductEnabledOptions(product: number, options:
    {
      optionvalues?: number[];
      productvalues?: number[];
      cartitems?: Array<{ product: number; options: number; configuration: unknown }>;
      allowselectionoutofstock?: boolean;
    }): Promise<ProductEnabledOptionsResult> {
    const lock = dompack.flagUIBusy();
    try {
      // add the cart for stock purposes
      options.cartitems = this.getCartItemsForFilter();
      return await webshoprpc.getProductEnabledOptions(this._getRPCPointer(), product, options) as ProductEnabledOptionsResult;
    } finally {
      lock.release();
    }
  }

  /** Add a coupon or gift card code to the current checkout. */
  async addDiscountCode(code: string, { scrollToCheckout = false } = {}) {
    const checkoutwidget = getCheckoutWidget();
    if (!checkoutwidget)
      throw new Error(`This page has no checkoutwidget - addDiscountCode not available`);

    return await checkoutwidget.addDiscountCode(code, { scrollToCheckout });
  }
}

export default WebShop;

import './reviews.scss'
import * as dompack from 'dompack';
import * as text from 'dompack/types/text';

import "@mod-forshops/pages/reviews/reviews.es";

function enableMarkdownHyperlinksInTextNode(node)
{
  let content = node.textContent;
  let replacements = [];
  while(true)
  {
    let match = content.match(/^(.*)\[([^\]]+)\]\(([^\)]+)\)(.*)$/);
    if(!match)
    {
      replacements.push(content);
      break;
    }

    replacements.push(match[1], <a rel="nofollow" href={match[2]}>{match[3]}</a>);
    content=match[4];
  }
  console.log(replacements);
  if(replacements.length > 1) //we obviously did something
    node.replaceWith(...replacements);
}

function enableMarkdownHyperlinks(node)
{
  for(let subnode of [ node, ...node.querySelectorAll('*') ])
    for(let child of subnode.childNodes)
      if(child.nodeType == 3) //text nodes
        enableMarkdownHyperlinksInTextNode(child);
}

dompack.register("#reviewform button", node => {
  node.classList.add("btn");
  node.classList.add("btn-primary");
});

dompack.register(".reviewitem__balloon", enableMarkdownHyperlinks);
